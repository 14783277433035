import React from 'react'
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import Layout from '../pages/Layout'
import Home from '../pages/Home'
import Connect from '../pages/Connect'
import AppConnect from '../pages/AppConnect'

const Routing = () => {
  return (
    <BrowserRouter>
        <Routes path='/' element={<Layout/>}>

        <Route path='/' element={<Home/>}/>
        <Route path='/apps' element={<Connect/>}/>
        <Route path='/apps/connect' element={<AppConnect/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default Routing