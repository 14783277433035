import React from 'react'
import Wallets from '../components/Wallets'
import {Link} from  'react-router-dom'
import Loader from '../components/Loader'

const Connect = () => {

  const toggleConnection = () => {
    const loader = document.querySelector('.loader');
    const overlay = document.querySelector('.overlay');
    overlay.style.visibility = 'visible'; 
    setTimeout(() => {
      loader.style.visibility='hidden'
      const popUp = document.querySelector('.popUp');
      popUp.style.visibility = 'visible'; 
  }, 3500);
    
}
const toggleNoConnection = () => {
  const overlay = document.querySelector('.overlay');
    overlay.style.visibility = 'hidden'; 
    const popUp = document.querySelector('.popUp');
      popUp.style.visibility = 'hidden'; 
      const loader = document.querySelector('.loader');
      loader.style.visibility=''
}

  return (
      <div className='space-y-10 text-white bg-bgColor'>
        <div className='overlay flex flex-col justify-center items-center'>
        <Loader/>
          <div className='popUp bg-white p-4 text-black'>
            {/* <div className='loader'> Loading</div> */}
            <p onClick={toggleNoConnection} className='text-bold text-2xl justify-end self-end cursor-pointer'>X</p>
            <div className='flex flex-col'>
              <p className='text-2xl text-center'>
                WalletConnect
              </p>
              <div className='flex p-6 py-8 space-x-4 justify-center items-center'>
                <p className='text-dangerRed text-2xl'>
                  Error Connecting
                </p>
                <Link to='/apps/connect'>
                <button className='bg-appWallet p-2 rounded-lg text-white text-2xl'>
                  Connect Manually
                </button>
                </Link>
              </div>
              <div className='flex flex-col p-6 py-8'>
                <p className='text-2xl'>Wallet Connect</p>
                <p>Easy-to-use browser extention</p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex p-4 justify-between'>
        <p className='text-2xl text-gray'>App</p>
        <img src='./Assets/Wallets/walletconnect-logo.svg' alt='walletConnect' style={{ width:87.25, borderRadius:50 }}/>
        <p className='text-2xl text-gray'>Wallets</p>
        </div>
        <div className='flex justify-center items-center'>
        <p className='text-4xl text-gray'>RPC-Dapps</p>
        </div>
        <div className='py-4 grid grid-cols-2 md:grid-cols-4 gap-10'>
          {
          //Wallets 
          }
          {Wallets.map((wallet, index)=>(
                
              <div onClick={toggleConnection} className='icon flex flex-col cursor-pointer space-y-4 justify-center items-center' key={index}>
                <img src={wallet.icon} alt={wallet.name}  style={{ width:100, borderRadius:50 }}/>
                <p className='text-blue text-center '>{wallet.name}</p>
              </div>
                
            ))}
        </div>
      </div>
  )
}

export default Connect