import React from 'react'
import Services from '../components/Services'
import { Link } from 'react-router-dom' 

const Home = () => {
  return (
    <div className=' px-4 md:px-20 py-4 space-y-10 text-white'>
      <div className='flex justify-between'>
        <h1 className='text-3xl text-white'>RPC<span className='text-initialColor'>-Dapps</span></h1>
        <div className='hidden md:flex space-x-4'>
          <Link to='/'>
            <h1 className='text-2xl text-initialColor'>Home</h1>
          </Link>
          
          <Link to='apps'>
            <h1 className='text-2xl text-white'>Connect</h1>
          </Link>
        </div>
        </div>
      <div className='flex flex-col-reverse md:flex-row'>
      <div className='space-y-6'>
        <h2 className='text-white text-3xl'>Node Functions</h2>
        
        <p className='text-white text-2xl'>TOKEN SWAP & CLAIM TOKEN BRIDGE • LIQUIDITY POOL AND FARM • DEPOSITS AND WITHDRAWALS • TOKENS STAKE AND UNSTAKE • LEDGER AND TREZOR HARDWARE • DEX WEBSITE ERROR • KYC & WHITELIST & ALLOCATION • AIRDROPS • COMPROMISED WALLET RECLAIM • MISSING TOKENS • UNABLE TO ACCESS WALLET • NFT'S AND NFT'S RELATED ISSUES • GENERAL WALLET ISSUES</p>
        <button className='text-white bg-initialColor py-2 px-4 ' style={{ borderRadius:'2px' }} >Start Here</button>
      </div>
      <img src='./Assets/Backgrounds/DDN.png' alt='DDN' className='md:w-1/2 m-5'/>
      </div>
      <div className='flex justify-center items-center'>
      <h1 className='text-4xl text-bold'>Services</h1>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-6'>
        {
          //list of services
        }
        {Services.map((service, index) => (
        <div className='flex-col justify-between bg-bgColor p-6 rounded-lg space-y-4'>
          <img src='./Assets/Wallets/monitor.png' alt='icon'/>
          <p className='text-2xl md:text-3xl'>
            {service.title}
          </p>
          <p className=''>
            {service.detail}
          </p>
          <div className='m-6'>
          <Link to='apps' >
          <button className='text-black bg-white py-2 px-4 ' style={{ borderRadius:2 }}>Select</button>
          </Link>
          </div>
        </div>
        ))}
      </div>
      <div className='flex flex-col justify-center p-4 space-y-4 items-center'>
      <p>
      Ready to resolve your blockchain issues?
      </p>
      <p>
      We are here to provide help.
      </p>
      <button className='text-white bg-initialColor py-2 px-4 ' style={{ borderRadius:'2px' }}>Start Here</button>
      </div>
    </div>
  )
}

export default Home