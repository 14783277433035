import React from 'react'

const Loader = ( ) =>{

    return(
        <div className=''>
            <div className="loader">

            </div>
        </div>
    )
}
export default Loader